import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClearingTicket, ClearingTicketService } from '@adlatus-gui/domain/clearing';

@Injectable({
  providedIn: 'root'
})
export class ClearingTicketUtils {
  public static readonly _xTotalCount = 'X-Total-Count';

  private _totalInbox = new BehaviorSubject<string>('0');
  private _totalOutbox = new BehaviorSubject<string>('0');
  private _totalMyIssues = new BehaviorSubject<string>('0');


  get totalInbox$(): Observable<string> {
    return this._totalInbox.asObservable();
  }

  get totalOutbox$(): Observable<string> {
    return this._totalOutbox.asObservable();
  }

  get totalMyIssues$(): Observable<string> {
    return this._totalMyIssues.asObservable();
  }

  updateInboxCount(count: string): void {
    this._totalInbox.next(count);
  }

  updateOutboxCount(count: string): void {
    this._totalOutbox.next(count);
  }

  updateMyIssuesCount(count: string): void {
    this._totalMyIssues.next(count);
  }

  constructor(private clearingTicketService: ClearingTicketService) {
  }

  listClearingTicketByProcessor(processor: string, offset: number, limit: number): Observable<HttpResponse<Array<ClearingTicket>>> {
    return this.clearingTicketService.listClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      undefined,  // originator
      processor,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      offset,     // offset
      limit,      // limit
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  listClearingTicketByOriginator(originator: string, offset: number, limit: number): Observable<HttpResponse<Array<ClearingTicket>>> {
    return this.clearingTicketService.listClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      originator,  // originator
      undefined,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      offset,     // offset
      limit,      // limit
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  inboxClearingTickets(processor: string): Observable<HttpResponse<Array<ClearingTicket>>> {
    return this.clearingTicketService.listClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      undefined,  // originator
      processor,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      0,     // offset
      10000,      // limit
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  outboxClearingTickets(originator: string): Observable<HttpResponse<Array<ClearingTicket>>> {
    return this.clearingTicketService.listClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      originator,  // originator
      undefined,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      0,     // offset
      10000,      // limit
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  myTickets(): Observable<Array<ClearingTicket>> {
    return this.clearingTicketService.listMyIssues(
      undefined,  // ticketType
      undefined,  // status
      undefined,  // originator
      undefined,  // processor
      undefined,  // severity
      undefined,  // creationDateTo
      0,          //page
      1000,       // lastUpdateFrom
      undefined
    );
  }
}
